import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostCard from "../components/post-card/post-card";
import SEO from "../components/seo";
import {
  TagPostsWrapper,
  TagPageHeading,
  TagName,
  TagSubName,
} from "./templates.style";

const Tags = ({ pageContext, data }: any) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;

  return (
    <Layout>
      <SEO title={tag} description={`${totalCount} posts en total`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          <TagSubName>{`${totalCount} posts en total`}</TagSubName>
        </TagPageHeading>
        {edges.map(({ node, index }: any) => (
          <PostCard
            key={node.fields.slug}
            title={node.frontmatter.title}
            url={"/musica/" + node.fields.slug}
            description={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMMM [</span>]", locale: "es")
            title
            tags
            description
          }
        }
      }
    }
  }
`;
